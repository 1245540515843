@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    @apply scroll-smooth;
  }

  body {
    @apply theme-transition bg-gray-100 dark:bg-neutral-900;
  }
}

@layer components {
  .theme-transition {
    @apply transition-colors duration-1000;
  }

  .btns {
    @apply cursor-pointer transition-all duration-300 pc-hover:hover:scale-105;
  }

  .navbar-items {
    @apply col-span-2 md:col-auto btns md:flex md:flex-col md:justify-center md:items-center scale-100 font-semibold text-right md:text-center my-2 md:my-0 md:px-2;
  }

  .text {
    @apply text-black dark:text-white;
  }

  .wrapper {
    @apply lg:container py-3 px-5 lg:px-0 text h-full;
  }

  .content-flex-wrapper {
    @apply flex flex-col justify-around items-center md:py-1 md:block w-full;
  }

  section {
    @apply border-b border-gray-300 dark:border-gray-700 p-4 h-px md:h-full;
  }

  .section-title {
    @apply text-left font-bold md:text-center text-yellow-500;
  }

  .contact-form-label {
    @apply w-full block text-gray-700 dark:text-white font-semibold md:my-5;
  }

  .contact-form-input {
    @apply block w-full bg-gray-200 dark:bg-gray-800 border-2 border-gray-200 text-gray-700 dark:text-white leading-tight focus:outline-none focus:bg-white rounded shadow p-2 my-1 transition-colors;
  }
}

/* FONTS */
/* Display */
@font-face {
  font-family: "Display";
  src: url("./fonts/SFProDisplay/SF-Pro-Display-Black.otf") format("opentype");
  font-weight: 900;
}

@font-face {
  font-family: "Display";
  src: url("./fonts/SFProDisplay/SF-Pro-Display-Heavy.otf") format("opentype");
  font-weight: 800;
}

@font-face {
  font-family: "Display";
  src: url("./fonts/SFProDisplay/SF-Pro-Display-Bold.otf") format("opentype");
  font-weight: 700;
}

@font-face {
  font-family: "Display";
  src: url("./fonts/SFProDisplay/SF-Pro-Display-Semibold.otf")
    format("opentype");
  font-weight: 600;
}

@font-face {
  font-family: "Display";
  src: url("./fonts/SFProDisplay/SF-Pro-Display-Medium.otf") format("opentype");
  font-weight: 500;
}

@font-face {
  font-family: "Display";
  src: url("./fonts/SFProDisplay/SF-Pro-Display-Regular.otf") format("opentype");
  font-weight: 400;
}

@font-face {
  font-family: "Display";
  src: url("./fonts/SFProDisplay/SF-Pro-Display-Light.otf") format("opentype");
  font-weight: 300;
}

@font-face {
  font-family: "Display";
  src: url("./fonts/SFProDisplay/SF-Pro-Display-Ultralight.otf")
    format("opentype");
  font-weight: 200;
}

@font-face {
  font-family: "Display";
  src: url("./fonts/SFProDisplay/SF-Pro-Display-Thin.otf") format("opentype");
  font-weight: 100;
}

/* Text */
@font-face {
  font-family: "Text";
  src: url("./fonts/SFProText/SF-Pro-Text-Heavy.otf") format("opentype");
  font-weight: 800;
}

@font-face {
  font-family: "Text";
  src: url("./fonts/SFProText/SF-Pro-Text-Bold.otf") format("opentype");
  font-weight: 700;
}

@font-face {
  font-family: "Text";
  src: url("./fonts/SFProText/SF-Pro-Text-Semibold.otf") format("opentype");
  font-weight: 600;
}

@font-face {
  font-family: "Text";
  src: url("./fonts/SFProText/SF-Pro-Text-Medium.otf") format("opentype");
  font-weight: 500;
}

@font-face {
  font-family: "Text";
  src: url("./fonts/SFProText/SF-Pro-Text-Regular.otf") format("opentype");
  font-weight: 400;
}

@font-face {
  font-family: "Text";
  src: url("./fonts/SFProText/SF-Pro-Text-Light.otf") format("opentype");
  font-weight: 300;
}
